import {Pipe, PipeTransform} from '@angular/core';

export type Address = {
    line: string | string[];
    city: string;
    state: string;
    zip: string;
};

@Pipe({name: 'address'})
export class AddressPipe implements PipeTransform {
    transform(value: Address): string {
        if (!value || Object.keys(value).length === 0) {
            return '-';
        }
        return `${Array.isArray(value.line) ? value.line.join(', ') : value.line}, ${value.city}, ${value.state}, ${value.zip}`;
    }
}
