@import "../../styles/variables";

app-top-color-card {
    display: flex;
    flex-direction: column;
}

.section-head > .title.text-unmuted {
    color: $black;
}

.top-color-card {
    border-top-width: 4px;
    border-top-style: solid;
    position: relative;

    .more-actions {
        position: absolute;
        top: 6px;
        right: 6px;
    }
}
